import React, { useState, useEffect, useRef } from 'react'
import { CustomHashLink } from 'components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { logo } from 'utils/consts'
import LanguageSwitcher from './LanguageSwitcher'
import { Img } from '../Img'

interface NavItem {
  label: string
  path: string
  subItems?: { label: string; path: string }[]
}

export const NavBar: React.FC = () => {
  const { t } = useTranslation()
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [isVisible, setIsVisible] = useState(true)
  const [lastScrollY, setLastScrollY] = useState(0)
  const sidebarRef = useRef<HTMLDivElement>(null)
  const toggleButtonRef = useRef<HTMLDivElement>(null)
  const [openDropdowns, setOpenDropdowns] = useState<string[]>([])

  const navItems: NavItem[] = [
    {
      label: t('navbar.whyAI'),
      path: '/#whyAI',
    },
    {
      label: t('navbar.whatWeDo'),
      path: '/#whatWeDo',
    },
    {
      label: t('navbar.howWeWork'),
      path: '/#howWeWork',
    },
    {
      label: t('navbar.ourProjects'),
      path: '/ourProjects',
    },
    {
      label: t('navbar.whatWeUse'),
      path: '/whatWeUse',
    },
    {
      label: t('navbar.contact'),
      path: '/contact',
    },
  ]

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isSidebarOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(event.target as Node)
      ) {
        setIsSidebarOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isSidebarOpen])

  useEffect(() => {
    const controlNavbar = () => {
      const currentScrollY = window.scrollY

      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        setIsVisible(false)
      } else {
        setIsVisible(true)
      }

      setLastScrollY(currentScrollY)
    }

    window.addEventListener('scroll', controlNavbar)

    return () => {
      window.removeEventListener('scroll', controlNavbar)
    }
  }, [lastScrollY])

  const toggleDropdown = (path: string) => {
    setOpenDropdowns((prev) =>
      prev.includes(path) ? prev.filter((p) => p !== path) : [...prev, path]
    )
  }

  const handleNavigation = () => {
    setIsSidebarOpen(false)
    setOpenDropdowns([])
  }

  return (
    <>
      {isSidebarOpen && <div className='navbar__overlay' onClick={toggleSidebar} />}
      <div className={`navbar ${!isVisible ? 'navbar--hidden' : ''}`}>
        <div className='navbar__left'>
          <Link to='/' onClick={handleNavigation}>
            <Img src={logo} width={180} />
          </Link>
        </div>
        <div className={`navbar__center ${isSidebarOpen ? 'open' : ''}`} ref={sidebarRef}>
          {navItems.map((item) => (
            <div className='navbar__menu-dropdown' key={item.path}>
              {item.subItems ? (
                <>
                  <div className='navbar__navitem' onClick={() => toggleDropdown(item.path)}>
                    {item.label}
                  </div>
                  <div className='navbar__hover-bridge' />
                </>
              ) : (
                <CustomHashLink
                  to={item.path}
                  className='navbar__navitem'
                  onClick={handleNavigation}
                >
                  {item.label}
                </CustomHashLink>
              )}
              {item.subItems && (
                <div
                  className={`navbar__dropdown-list ${openDropdowns.includes(item.path) ? 'open' : ''}`}
                >
                  <div className='navbar__dropdown-link-list'>
                    {item.subItems.map((subItem) => (
                      <CustomHashLink
                        key={subItem.path}
                        to={subItem.path}
                        className='navbar__dropdown-item'
                        style={{ textDecoration: 'none' }}
                        onClick={handleNavigation}
                      >
                        {subItem.label}
                      </CustomHashLink>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
          <div className='navbar__divider'></div>
          <LanguageSwitcher />
        </div>
        <div className='navbar__mobile-toggle' onClick={toggleSidebar} ref={toggleButtonRef}>
          <Img src='icons/3-lines-menu.png' width={30} alt='Menu' />
        </div>
      </div>
    </>
  )
}

export default NavBar
