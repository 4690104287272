import { useCallback } from 'react'
import i18next from 'i18next'

const countryToLanguage: { [key: string]: string } = {
  DE: 'de',
  AT: 'de',
  CH: 'de',
  NL: 'nl',
  BE: 'nl',
  PL: 'pl',
  SE: 'se',
  FR: 'fr',
}

export const useInitialLanguage = () => {
  const initialize = useCallback(async () => {
    if (!localStorage.getItem('i18nextLng')) {
      try {
        const response = await fetch('https://ipapi.co/json/')
        const data = await response.json()
        const countryCode = data.country_code
        const languageCode = countryToLanguage[countryCode] || 'en'

        localStorage.setItem('i18nextLng', languageCode)
        await i18next.changeLanguage(languageCode)
      } catch (error) {
        console.error('Failed to initialize language:', error)
        localStorage.setItem('i18nextLng', 'en')
        await i18next.changeLanguage('en')
      }
    }
  }, [])

  return { initialize }
}
