import React from 'react'

type Props = {
  className?: string
  src: string
  alt?: string
  width?: string | number
  onClick?: (data?: unknown) => void
  isBackground?: boolean
  style?: React.CSSProperties
}

export const Img: React.FC<Props> = ({ className, src, alt, width, onClick, isBackground }) => {
  const isExternalUrl = src.startsWith('http') || src.startsWith('https')

  if (isBackground) {
    return (
      <div
        className={`background-image ${className || ''}`}
        style={{
          width,
          backgroundImage: isExternalUrl ? `url(${src})` : `url(${require(`../images/${src}`)})`,
        }}
        onClick={onClick}
        role='img'
        aria-label={alt}
      />
    )
  }

  if (isExternalUrl) {
    return <img className={className} src={src} alt={alt} width={width} onClick={onClick} />
  }

  return (
    <img
      className={className}
      src={require(`../images/${src}`)}
      alt={alt}
      width={width}
      onClick={onClick}
    />
  )
}
