import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

declare global {
  interface Window {
    i18next: typeof i18n
  }
}

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    ns: ['translations', 'clients'],
    defaultNS: 'translations',
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  })

// Clear any existing language preference in localStorage
localStorage.removeItem('i18nextLng')

window.i18next = i18n

export default i18n
