import React from 'react'
import { CustomHashLink, Button } from 'components'
import { ButtonStyle } from 'utils/enums'

export const NotFound: React.FC = () => {
  return (
    <>
      <section className='not-found'>
        <div className='not-found__content'>
          <h1>404</h1>
          <h2>Oops...</h2>
          <p>Page not found</p>
          <CustomHashLink to='/'>
            <Button style={ButtonStyle.TRANSPARENT} text='Back to Home' />
          </CustomHashLink>
        </div>
      </section>
    </>
  )
}
