import React from 'react'
import { useTranslation } from 'react-i18next'

export const WhyAI: React.FC = () => {
  const { t } = useTranslation()

  return (
    <section id='whyAI' className='why-ai'>
      <h2>{t('home.whyAI.title')}</h2>
      <div className='why-ai__content'>
        <div className='why-ai__column'>
          <h3>{t('home.whyAI.column1.title')}</h3>
          <p>{t('home.whyAI.column1.description')}</p>
        </div>
        <div className='why-ai__divider' />
        <div className='why-ai__column'>
          <h3>{t('home.whyAI.column2.title')}</h3>
          <p>{t('home.whyAI.column2.description')}</p>
        </div>
        <div className='why-ai__divider' />
        <div className='why-ai__column'>
          <h3>{t('home.whyAI.column3.title')}</h3>
          <p>{t('home.whyAI.column3.description')}</p>
        </div>
      </div>
    </section>
  )
}
