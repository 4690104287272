import React, { useState } from 'react'
import { Button, Img } from 'components'
import { useTranslation } from 'react-i18next'
import { ButtonStyle } from 'utils/enums'

export const OurServices = () => {
  const [activePopup, setActivePopup] = useState<number | null>(null)
  const { t } = useTranslation()

  const services = [
    {
      id: 'automation',
      title: t('home.ourServices.services.automation.title'),
      icon: 'icons/automation.png',
      description: t('home.ourServices.services.automation.description'),
      fullDescription: t('home.ourServices.services.automation.fullDescription'),
    },
    {
      id: 'tailorMade',
      title: t('home.ourServices.services.tailorMade.title'),
      icon: 'icons/tailored.png',
      description: t('home.ourServices.services.tailorMade.description'),
      fullDescription: t('home.ourServices.services.tailorMade.fullDescription'),
    },
    {
      id: 'cloud',
      title: t('home.ourServices.services.cloud.title'),
      icon: 'icons/cloud.png',
      description: t('home.ourServices.services.cloud.description'),
      fullDescription: t('home.ourServices.services.cloud.fullDescription'),
    },
    {
      id: 'aiAgents',
      title: t('home.ourServices.services.aiAgents.title'),
      icon: 'icons/agents.png',
      description: t('home.ourServices.services.aiAgents.description'),
      fullDescription: t('home.ourServices.services.aiAgents.fullDescription'),
    },
    {
      id: 'databases',
      title: t('home.ourServices.services.databases.title'),
      icon: 'icons/outreach.png',
      description: t('home.ourServices.services.databases.description'),
      fullDescription: t('home.ourServices.services.databases.fullDescription'),
    },
  ]

  return (
    <section id='whatWeDo' className='our-services'>
      <h2>{t('home.ourServices.title')}</h2>
      <div className='our-services__grid'>
        {services.map((service) => (
          <div key={service.id} className='our-services__item'>
            <h3>{service.title}</h3>
            <div className='our-services__icon'>
              <Img src={service.icon} alt={service.title} width={145} />
            </div>
            <p>{service.description}</p>
            <Button
              className='our-services__read-more'
              onClick={() => setActivePopup(services.findIndex((s) => s.id === service.id))}
              text={t('home.ourServices.readMore')}
              style={ButtonStyle.DARK}
            />
          </div>
        ))}
      </div>

      {activePopup !== null && (
        <div className='our-services__popup-overlay' onClick={() => setActivePopup(null)}>
          <div className='our-services__popup' onClick={(e) => e.stopPropagation()}>
            <h3>{services[activePopup].title}</h3>
            <p>{services[activePopup].fullDescription}</p>
            <button
              type='button'
              className='our-services__popup-close'
              onClick={() => setActivePopup(null)}
            >
              ×
            </button>
          </div>
        </div>
      )}
    </section>
  )
}
