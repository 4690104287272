export enum Language {
  EN = 'en',
  PL = 'pl',
}

export enum ButtonStyle {
  PINK = 'pink',
  GREEN = 'green',
  TRANSPARENT = 'transparent',
  TRANSPARENT_BLUE = 'transparent-blue',
  DARK = 'dark',
  RED = 'red',
}
