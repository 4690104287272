import React, { useState, useEffect } from 'react'
import { Img, Button, Notification } from 'components'
import { useTranslation } from 'react-i18next'
import { ButtonStyle } from 'utils/enums'

interface FormData {
  firstName: string
  lastName: string
  companyName: string
  email: string
  jobTitle: string
  source: string
  message: string
}

// Use process.env instead of import.meta.env
const accessKey = process.env.REACT_APP_WEB3FORMS_ACCESS_KEY || ''

export const Contact: React.FC = () => {
  const { t } = useTranslation()
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    jobTitle: '',
    source: '',
    message: '',
  })
  const [isFormValid, setIsFormValid] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [notification, setNotification] = useState<{
    show: boolean
    message: string
    type: 'success' | 'error'
  }>({
    show: false,
    message: '',
    type: 'success',
  })

  useEffect(() => {
    if (!accessKey) {
      console.error('Web3Forms access key is not configured')
    }
  }, [])

  useEffect(() => {
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
    const areAllFieldsFilled = Object.values(formData).every((value) => value.trim() !== '')
    setIsFormValid(areAllFieldsFilled && isEmailValid)
  }, [formData])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmit = async () => {
    if (!isFormValid || isSubmitting || !accessKey) return

    setIsSubmitting(true)

    try {
      const submitFormData = new FormData()
      submitFormData.append('access_key', accessKey)
      submitFormData.append('name', `${formData.firstName} ${formData.lastName}`)
      submitFormData.append('email', formData.email)
      submitFormData.append('company', formData.companyName)
      submitFormData.append('job_title', formData.jobTitle)
      submitFormData.append('source', formData.source)
      submitFormData.append('message', formData.message)

      submitFormData.append('subject', 'New Contact Form Submission')
      submitFormData.append('from_name', 'TaKo AI Website Contact')

      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        body: submitFormData,
      })

      const data = await response.json()

      if (data.success) {
        setNotification({
          show: true,
          message: t('contact.form.successMessage'),
          type: 'success',
        })

        // Reset form
        setFormData({
          firstName: '',
          lastName: '',
          companyName: '',
          email: '',
          jobTitle: '',
          source: '',
          message: '',
        })
      } else {
        throw new Error(data.message || 'Submission failed')
      }
    } catch (error) {
      console.error('Error:', error)
      setNotification({
        show: true,
        message: t('contact.form.errorMessage'),
        type: 'error',
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <section className='contact'>
        <div className='contact__background'>
          <Img src='contact-bg.png' width='100%' alt='Contact background' />
        </div>

        <div className='contact__container'>
          <div className='contact__content'>
            <div className='contact__text'>
              <h1 className='contact__title'>{t('contact.title')}</h1>
              <div className='contact__description'>
                <p>{t('contact.description1')}</p>
                <p>{t('contact.description2')}</p>
                <p className='contact__info'>{t('contact.contactInfo')}</p>
                <a href='mailto:contact@takoai.pl' className='contact__email'>
                  contact@takoai.pl
                </a>
              </div>
            </div>

            <div className='contact__form-container'>
              <form onSubmit={handleSubmit} className='contact__form'>
                <div className='contact__form-row'>
                  <div className='contact__form-group'>
                    <label>{t('contact.form.firstName')}</label>
                    <input
                      type='text'
                      name='firstName'
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='contact__form-group'>
                    <label>{t('contact.form.lastName')}</label>
                    <input
                      type='text'
                      name='lastName'
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className='contact__form-group'>
                  <label>{t('contact.form.companyName')}</label>
                  <input
                    type='text'
                    name='companyName'
                    value={formData.companyName}
                    onChange={handleChange}
                  />
                </div>

                <div className='contact__form-group'>
                  <label>{t('contact.form.email')}</label>
                  <input type='email' name='email' value={formData.email} onChange={handleChange} />
                </div>

                <div className='contact__form-group'>
                  <label>{t('contact.form.jobTitle')}</label>
                  <input
                    type='text'
                    name='jobTitle'
                    value={formData.jobTitle}
                    onChange={handleChange}
                  />
                </div>

                <div className='contact__form-group'>
                  <label>{t('contact.form.source')}</label>
                  <input
                    type='text'
                    name='source'
                    value={formData.source}
                    onChange={handleChange}
                  />
                </div>

                <div className='contact__form-group'>
                  <label>{t('contact.form.message')}</label>
                  <textarea
                    name='message'
                    value={formData.message}
                    onChange={handleChange}
                    rows={4}
                  />
                </div>

                <div className='contact__form-submit'>
                  <Button
                    style={ButtonStyle.RED}
                    onClick={() => handleSubmit()}
                    text={isSubmitting ? t('contact.form.sending') : t('contact.form.submit')}
                    disabled={!isFormValid || isSubmitting}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        {notification.show && (
          <Notification
            message={notification.message}
            type={notification.type}
            onClose={() => setNotification((prev) => ({ ...prev, show: false }))}
          />
        )}
      </section>
    </>
  )
}
