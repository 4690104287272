import React from 'react'
import { Img } from 'components'
import { useTranslation } from 'react-i18next'

export const Process = () => {
  const { t } = useTranslation()

  const steps = [
    {
      title: t('home.process.steps.step1.title'),
      description: t('home.process.steps.step1.description'),
    },
    {
      title: t('home.process.steps.step2.title'),
      description: t('home.process.steps.step2.description'),
    },
    {
      title: t('home.process.steps.step3.title'),
      description: t('home.process.steps.step3.description'),
    },
    {
      title: t('home.process.steps.step4.title'),
      description: t('home.process.steps.step4.description'),
    },
  ]

  return (
    <section id='howWeWork' className='process'>
      <div className='process__title'>{t('home.process.title')}</div>

      <div className='process__image'>
        <Img src='icons/process.png' alt={t('home.process.title')} width='100%' />
      </div>

      <div className='process__steps'>
        {steps.map((step) => (
          <div key={step.title} className='process__step'>
            <h3 className='process__step-title'>{step.title}</h3>
            <p>{step.description}</p>
          </div>
        ))}
      </div>

      <div className='process__why-us'>
        <h2>{t('home.process.whyUs.title')}</h2>
        <p>{t('home.process.whyUs.description')}</p>
      </div>
    </section>
  )
}
