import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'

import './styles/font'
import './styles/index.scss'
import './utils/i18n'

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <React.Suspense fallback={<div>Loading...</div>}>
      <App />
    </React.Suspense>
  </React.StrictMode>
)
