import React, { useEffect, useState } from 'react'
import { Footer, NavBar } from 'components'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { useInitialLanguage } from './hooks/useInitialLanguage'
import { Contact, NotFound, Home, OurProjects, Stack } from './pages'
import './styles/index.scss'

const App: React.FC = () => {
  const [isLanguageInitialized, setIsLanguageInitialized] = useState(false)
  const { initialize } = useInitialLanguage()

  useEffect(() => {
    console.log('useEffect')
    const init = async () => {
      await initialize()
      setIsLanguageInitialized(true)
    }
    init()
  }, [initialize])

  if (!isLanguageInitialized) {
    return <div>Loading...</div>
  }

  return (
    <Router>
      <div className='App'>
        <NavBar />
        <main className='main-content'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/Contact' element={<Contact />} />
            <Route path='/OurProjects' element={<OurProjects />} />
            <Route path='/WhatWeUse' element={<Stack />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  )
}

export default App
