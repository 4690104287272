import React from 'react'
import { Footer, Img } from 'components'
import { useTranslation } from 'react-i18next'

interface TechSection {
  title: string
  items: {
    icon: string
    alt: string
  }[]
}

export const Stack: React.FC = () => {
  const { t } = useTranslation()

  const sections: TechSection[] = [
    {
      title: 'AI/ML',
      items: [
        { icon: 'icons/langchain.png', alt: 'LangChain' },
        { icon: 'icons/langgraph.svg', alt: 'LangGraph' },
        { icon: 'icons/langsmith.png', alt: 'LangSmith' },
        { icon: 'icons/hf.avif', alt: 'HuggingFace' },
        { icon: 'icons/pytorch.webp', alt: 'PyTorch' },
        { icon: 'icons/tf.png', alt: 'TensorFlow' },
        { icon: 'icons/vertexai.png', alt: 'Vertex AI' },
        { icon: 'icons/cognitive-services.jpg', alt: 'Cognitive Services' },
        { icon: 'icons/bedrock.png', alt: 'Bedrock' },
        { icon: 'icons/gemini.png', alt: 'Gemini' },
        { icon: 'icons/claude.png', alt: 'Claude' },
        { icon: 'icons/openai.png', alt: 'OpenAI' },
        { icon: 'icons/langfuse.svg', alt: 'Langfuse' },
        { icon: 'icons/cursor.jpg', alt: 'Cursor' },
      ],
    },
    {
      title: 'Frontend',
      items: [
        { icon: 'icons/react.png', alt: 'React' },
        { icon: 'icons/streamlit.png', alt: 'Streamlit' },
        { icon: 'icons/flutter.png', alt: 'Flutter' },
        { icon: 'icons/wordpress.png', alt: 'WordPress' },
      ],
    },
    {
      title: 'Backend',
      items: [
        { icon: 'icons/python.png', alt: 'Python' },
        { icon: 'icons/go.png', alt: 'GO' },
        { icon: 'icons/flask.jpg', alt: 'Flask' },
        { icon: 'icons/fastapi.png', alt: 'FastAPI' },
        { icon: 'icons/postgresql.png', alt: 'PostgreSQL' },
      ],
    },
    {
      title: 'DevOps',
      items: [
        { icon: 'icons/git.png', alt: 'Git' },
        { icon: 'icons/github.png', alt: 'GitHub' },
        { icon: 'icons/actions.png', alt: 'Actions' },
        { icon: 'icons/docker.png', alt: 'Docker' },
        { icon: 'icons/supabase.jpg', alt: 'Supabase' },
        { icon: 'icons/gitlab.png', alt: 'GitLab' },
      ],
    },
    {
      title: 'Cloud',
      items: [
        { icon: 'icons/gcp.png', alt: 'Google Cloud Platform' },
        { icon: 'icons/aws.webp', alt: 'Amazon Web Services' },
        { icon: 'icons/azure.webp', alt: 'Microsoft Azure' },
        { icon: 'icons/firebase.png', alt: 'Firebase' },
        { icon: 'icons/vercel.png', alt: 'Vercel' },
        { icon: 'icons/digitalocean.png', alt: 'DigitalOcean' },
      ],
    },
  ]

  return (
    <>
      <section className='stack'>
        <h1 className='stack__title'>{t('navbar.whatWeUse')}</h1>
        <div className='stack__background'>
          <Img src='stack-bg.png' width='100%' alt='Stack background' />
        </div>

        <div className='stack__container'>
          <div className='stack__content'>
            {sections.map((section) => (
              <div key={section.title} className='stack__section'>
                <h2 className='stack__section-title'>{section.title}</h2>
                <div className='stack__grid'>
                  {section.items.map((item) => (
                    <div key={item.alt} className='stack__item'>
                      <Img src={item.icon} alt={item.alt} width={80} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}
