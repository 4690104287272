import React from 'react'
import { Img, CustomHashLink } from 'components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { logo } from 'utils/consts'

export const Footer: React.FC = () => {
  const { t } = useTranslation()

  return (
    <footer className='footer'>
      <div className='footer__container'>
        <div className='footer__columns'>
          <div className='footer__logo'>
            <Img src={logo} width={180} />
            <div className='footer__social'>
              <a href='https://github.com/takoai-pl' target='_blank' rel='noopener noreferrer'>
                <Img src='icons/github-white.png' width={24} alt='GitHub' />
              </a>
              <a
                href='https://linkedin.com/company/matait'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Img src='icons/linkedin-white.png' width={24} alt='LinkedIn' />
              </a>
            </div>
          </div>

          <div className='footer__section'>
            <h3 className='footer__title'>{t('footer.company.title')}</h3>
            <div className='footer__company-info'>
              <p>{t('footer.company.name')}</p>
              <p>{t('footer.company.legalName')}</p>
              <p>{t('footer.company.nip')}</p>
              <p>{t('footer.company.regon')}</p>
            </div>
          </div>

          <div className='footer__section'>
            <h3 className='footer__title'>{t('footer.location.title')}</h3>
            <div className='footer__address'>
              <p>{t('footer.location.address1')}</p>
              <p>{t('footer.location.address2')}</p>
              <p>{t('footer.location.country')}</p>
            </div>
          </div>

          <div className='footer__section'>
            <h3 className='footer__title'>{t('footer.legal.title')}</h3>
            <nav className='footer__legal'>
              <Link to='/privacy-policy-takoai.pdf' target='_blank' className='footer__link'>
                {t('footer.legal.cookies')}
              </Link>
              <Link to='/privacy-policy-takoai.pdf' target='_blank' className='footer__link'>
                {t('footer.legal.privacy')}
              </Link>
            </nav>
          </div>

          <div className='footer__section'>
            <h3 className='footer__title'>{t('footer.navigation.title')}</h3>
            <nav className='footer__nav'>
              <CustomHashLink to='/#whyAI' className='footer__link'>
                {t('footer.navigation.whyAI')}
              </CustomHashLink>
              <CustomHashLink to='/#whatWeDo' className='footer__link'>
                {t('footer.navigation.whatWeDo')}
              </CustomHashLink>
              <CustomHashLink to='/#howWeWork' className='footer__link'>
                {t('footer.navigation.howWeWork')}
              </CustomHashLink>
              <Link to='/ourProjects' className='footer__link'>
                {t('footer.navigation.ourProjects')}
              </Link>
              <Link to='/whatWeUse' className='footer__link'>
                {t('footer.navigation.whatWeUse')}
              </Link>
              <Link to='/contact' className='footer__link'>
                {t('footer.navigation.contact')}
              </Link>
            </nav>
          </div>
        </div>
      </div>

      <div className='footer__copyright'>
        <p>{t('footer.copyright')}</p>
      </div>
    </footer>
  )
}
