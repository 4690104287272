import React from 'react'
import { useTranslation } from 'react-i18next'
import { Language } from '../../utils/enums'

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation()
  const [isOpen, setIsOpen] = React.useState(false)

  const languages: Record<Language, { nativeName: string }> = {
    en: { nativeName: 'English' },
    pl: { nativeName: 'Polski' },
  }

  const getCurrentLanguage = () => {
    return (i18n.language in languages ? i18n.language : 'en') as Language
  }

  const changeLanguage = (lang: Language) => {
    i18n.changeLanguage(lang)
    setIsOpen(false)
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className='navbar__menu-dropdown'>
      <div className='navbar__navitem' onClick={toggleDropdown}>
        <span>{languages[getCurrentLanguage()].nativeName}</span>
      </div>
      <div className='navbar__hover-bridge' />
      <div className={`navbar__dropdown-list ${isOpen ? 'open' : ''}`}>
        <div className='navbar__dropdown-link-list'>
          <div className='navbar__links-wrap'>
            {Object.keys(languages)
              .filter((lang) => lang !== getCurrentLanguage())
              .map((lang) => (
                <div
                  key={lang}
                  className='navbar__dropdown-item'
                  onClick={() => changeLanguage(lang as Language)}
                >
                  {languages[lang as Language].nativeName}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LanguageSwitcher
