import React from 'react'
import { HashLink } from 'react-router-hash-link'

interface CustomHashLinkProps {
  to: string
  className?: string
  children: React.ReactNode
  style?: React.CSSProperties
  onClick?: () => void
}

export const CustomHashLink: React.FC<CustomHashLinkProps> = ({
  to,
  className,
  children,
  style,
  onClick,
}) => {
  const scrollWithOffset = (el: HTMLElement) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
    const yOffset = 0
    const isScrollingUp = yCoordinate < window.scrollY

    window.scrollTo({
      top: yCoordinate + (isScrollingUp ? yOffset : 0),
      behavior: 'smooth',
    })
  }

  return (
    <HashLink
      smooth
      to={to}
      className={className}
      scroll={scrollWithOffset}
      style={style}
      onClick={onClick}
    >
      {children}
    </HashLink>
  )
}
