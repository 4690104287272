import React from 'react'
import { Button, Img } from 'components'
import { Trans, useTranslation } from 'react-i18next'
import { ButtonStyle } from 'utils/enums'

export const Outro: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className='outro'>
      <Img src='contact-bg.png' width='100%' isBackground />
      <div className='outro__content'>
        <div className='outro__description'>
          <Trans
            i18nKey='home.outro.description'
            components={{
              highlight: <span className='outro__highlight' />,
            }}
          />
        </div>
        <Button
          style={ButtonStyle.TRANSPARENT}
          text={t('home.outro.buttons.contact')}
          onClick={() => (window.location.href = '/contact')}
        />
      </div>
    </div>
  )
}
