import React, { useEffect, useState, useCallback } from 'react'
import { Img, Intro, Outro } from 'components'
import { useTranslation } from 'react-i18next'
import { OurServices } from './OurServices'
import { Process } from './Process'
import { WhyAI } from './WhyAI'

export const Home: React.FC = () => {
  const { t } = useTranslation()
  const [currentText, setCurrentText] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const [optionIndex, setOptionIndex] = useState(0)

  const options = [
    t('home.intro.option1'),
    t('home.intro.option2'),
    t('home.intro.option3'),
    t('home.intro.option4'),
    t('home.intro.option5'),
  ]

  useEffect(() => {
    const typingSpeed = 50
    const deletingSpeed = 500
    const pauseTime = 2000
    let timeoutId: NodeJS.Timeout

    const type = () => {
      const currentOption = options[optionIndex]

      if (!isDeleting) {
        if (currentText.length < currentOption.length) {
          setCurrentText(currentOption.slice(0, currentText.length + 1))
          timeoutId = setTimeout(type, typingSpeed)
        } else {
          timeoutId = setTimeout(() => setIsDeleting(true), pauseTime)
        }
      } else {
        if (currentText.length > 0) {
          setCurrentText(currentText.slice(0, -1))
          timeoutId = setTimeout(type, deletingSpeed)
        } else {
          setIsDeleting(false)
          setOptionIndex((prev) => (prev + 1) % options.length)
          timeoutId = setTimeout(type, typingSpeed)
        }
      }
    }

    timeoutId = setTimeout(type, typingSpeed)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [currentText, isDeleting, optionIndex, options])

  const handleScroll = useCallback(() => {
    const backgrounds = document.querySelectorAll('.home__moving-background img')
    const containers = document.querySelectorAll('.home__moving-background')

    backgrounds.forEach((background, index) => {
      const container = containers[index] as HTMLElement
      if (background && container) {
        const rect = container.getBoundingClientRect()
        const viewportHeight = window.innerHeight

        if (rect.top < viewportHeight && rect.bottom > 0) {
          const scrollProgress = (viewportHeight - rect.top) / (viewportHeight + rect.height)
          const parallaxOffset = scrollProgress * 100
          const img = background as HTMLElement
          img.style.transform = `translateY(calc(-50% + ${parallaxOffset}px))`
        }
      }
    })
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    handleScroll()

    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  const description = (
    <div className='typing-container'>
      <span>{t('home.intro.subtitle')}&nbsp;</span>
      <span className='typed-text'>{currentText}</span>
      <span className='cursor'>|</span>
    </div>
  )

  return (
    <div className='home'>
      <div className='home__intro-bg'>
        <Img src='intro-bg.png' width='100%' />
      </div>
      <Intro headline={t('home.intro.title')} description={description} />
      <WhyAI />
      <div className='home__moving-background'>
        <Img src='ai-bg.png' width='100%' />
      </div>
      <OurServices />
      <div className='home__moving-background'>
        <Img src='process-bg.png' width='100%' />
      </div>
      <Process />
      <Outro />
    </div>
  )
}
