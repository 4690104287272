import React from 'react'
import './Notification.scss'

interface NotificationProps {
  message: string
  type: 'success' | 'error'
  onClose: () => void
}

export const Notification: React.FC<NotificationProps> = ({ message, type, onClose }) => {
  React.useEffect(() => {
    const timer = setTimeout(() => {
      onClose()
    }, 5000) // Hide after 5 seconds

    return () => clearTimeout(timer)
  }, [onClose])

  return (
    <div className={`notification notification--${type}`}>
      <p>{message}</p>
      <button onClick={onClose} className='notification__close' type='button'>
        ×
      </button>
    </div>
  )
}
