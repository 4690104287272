import React from 'react'
import { DownOutlined } from '@ant-design/icons'
import { CustomHashLink } from 'components'

interface IntroProps {
  headline: string
  description?: string | React.ReactNode
}

export const Intro: React.FC<IntroProps> = ({ headline, description }) => {
  return (
    <section className='intro'>
      <div className='intro__content'>
        <h1>{headline}</h1>
        {description && <p>{description}</p>}
      </div>
      <CustomHashLink to='#whyAI' className='intro__scroll-arrow'>
        <DownOutlined style={{ fontSize: '24px' }} />
      </CustomHashLink>
    </section>
  )
}
