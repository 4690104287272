import React from 'react'
import { Img } from 'components/Img'
import { ButtonStyle } from '../../utils/enums'

interface ButtonProps {
  text: string
  style?: ButtonStyle
  onClick?: () => void
  disabled?: boolean
  arrow?: boolean
  className?: string
}

export const Button: React.FC<ButtonProps> = ({
  text,
  style = ButtonStyle.TRANSPARENT,
  onClick,
  disabled = false,
  arrow = false,
  className,
}) => {
  return (
    <button
      className={`button ${style} ${className || ''}`}
      onClick={onClick}
      disabled={disabled}
      type='button'
    >
      <span className='button__text'>{text}</span>
      {arrow && (
        <>
          <div className='button__icon button__icon--default'>
            <Img src='icons/arrow-blue.png' width={20} />
          </div>
          <div className='button__icon button__icon--hover'>
            <Img src='icons/arrow-white.png' width={20} />
          </div>
        </>
      )}
    </button>
  )
}
